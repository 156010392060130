<template>
  <div class="module-category">
    <div class="utils">
      <el-button
        type="primary"
        style="float: right;"
        @click="handleAddCategory"
        size="small"
      >新增分类</el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="modules"
    >
      <el-table-column
        label="序号"
        type="index"
      ></el-table-column>
      <el-table-column label="分类名称">
        <template slot-scope="scope">
          <span v-show="!scope.row.isEdit">{{scope.row.name}}</span>
          <el-input
            size="small"
            type="text"
            v-show="scope.row.isEdit"
            v-model="scope.row.name"
          />
        </template>
      </el-table-column>
      <el-table-column label="图标">
        <template slot-scope="scope">
          <template v-if="scope.row.icon">
            <div
              class="icon"
              :style="{color: scope.row.color}"
            >
              <span
                :class="scope.row.icon"
                @click="handleVisible(scope.row)"
              >
              </span>
            </div>
          </template>
          <template v-else>
            <el-button
              type="text"
              @click="handleVisible(scope.row)"
            >选择</el-button>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="排序">
        <template slot-scope="scope">
          <span v-show="!scope.row.isEdit">{{scope.row.orderCond}}</span>
          <el-input
            size="small"
            type="text"
            v-show="scope.row.isEdit"
            v-model.number="scope.row.orderCond"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="编辑">
        <template slot-scope="scope">
          <div v-show="!scope.row.isEdit">
            <el-button
              size="small"
              type="text"
              @click="scope.row.isEdit = true"
            >编辑</el-button>
          </div>
          <div v-show="scope.row.isEdit">
            <el-button
              type="text"
              size="small"
              @click="handleEdit(scope.row)"
            >保存</el-button>
            <el-button
              type="text"
              size="small"
              @click="handleCancel"
            >取消</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="visible"
      :append-to-body="true"
      width="708px"
      title="选择图标"
    >
      <div class="icon-check">
        <ul class="icon-list">
          <li
            v-for="(item,i) in icons"
            :key="i"
            :class="{active: choosedIndex===i}"
            :style="{color: choosedIndex===i ?color: '#ccc'}"
            @click="choosedIndex=i"
          >
            <span :class="['iconfont',item.icon]"></span>
          </li>
        </ul>
        <div class="color-choose">
          <span class="purple">选择颜色：</span>
          <el-color-picker
            size="small"
            v-model="color"
          ></el-color-picker>
        </div>
      </div>
      <div slot="footer">
        <el-button
          type="primary"
          @click="getIcon(icons[choosedIndex]['icon'])"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // addCategoryPackage 新增套餐api

  import {categories, addCategory, updateCategroy} from '@/api/factory/application/module'

  import { ColorPicker } from 'element-ui'

  export default {
    name: 'module-category',
    inject: ['icons'],
    data () {
      return {
        loading: false,
        orderBy: 1,
        choosedIndex: -1,
        color: '#26C6DA',
        modules: [],
        addInfo: [],
        currentItem: null,
        visible: false,
        visiblePackage: false,
        packages: {
          name: '',
          price: 0,
          timeLength: 0
        },
        ops: scroll
      }
    },
    methods: {
      init () {
        this.loading = true
        categories().then(res => {
          if (res.code === 0) {
            this.modules = res.data.map(item => ({ ...item, isEdit: false })).sort((a, b) => a.orderCond - b.orderCond)
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      handleAddCategory () {
        this.modules.unshift({
          name: '',
          icon: '',
          content: '',
          color: '',
          orderCond: '',
          isEdit: true,
          isAdd: true
        })
      },
      handleVisible (item) {
        if (item.hasOwnProperty('isEdit')) {
          if (item.isEdit) {
            this.color = item.color
            this.currentItem = item
            this.icons.find((v, index) => {
              this.choosedIndex = index
              return v.icon === item.icon
            })
            this.visible = true
          }
        } else {
          this.color = item.color
          this.currentItem = item
          this.icons.find((v, index) => {
            this.choosedIndex = index
            return v.icon === item.icon
          })
          this.visible = true
        }
      },
      getIcon (icon) {
        if (this.currentItem) {
          this.currentItem.icon = icon
          this.currentItem.color = this.color
          this.visible = false
        }
      },
      handleCancel () {
        this.$confirm('是否确认放弃修改', '提示', { type: 'warning' }).then(() => {
          this.init()
        }).catch(() => {

        })
      },
      // 完成添加
      completeAdd (item, index) {
        let orderCond = item.orderCond || this.modules[this.modules.length - 1].orderCond + 1
        addCategory({ ...item, orderCond }).then(res => {
          if (res.code === 0) {
            this.addInfo.splice(index, 1)
            this.init()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 修改分类
      handleEdit (item) {

        let { uuid, name, icon, color, content, orderCond, isAdd } = item
        if (!isAdd) {
          updateCategroy({ uuid, name, icon, color, content, orderCond }).then(res => {
            if (res.code === 0) {
              this.init()
              this.$message.success('修改成功')
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          addCategory({ name, icon, color, content }).then(res => {
            if (res.code === 0) {
              this.init()
              this.$message.success('修改成功')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      // // 发布
      // handlePublish (sortUuid, name) {
      //   this.$confirm('确定发布"' + name + '"模块分类吗？', '提示').then(() => {
      //     publishCategory({ sortUuid }).then(res => {
      //       if (res.code === 0) {
      //         this.$message.success('发布成功')
      //         this.init()
      //       } else {
      //         this.$message.error(res.msg)
      //       }
      //     })
      //   })
      // },
      cancelAdd (index) {
        this.$confirm('是否取消新增模块', '提示').then(() => {
          this.addInfo.splice(index, 1)
        })
      }
    },
    mounted () {
      this.init()
    },
    components: {
      ElColorPicker: ColorPicker
    }
  }
</script>

<style lang="scss">
  .module-category{
    .utils{
      margin: $padding 0;
    }

  }
</style>
